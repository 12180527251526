import baseTheme from "@nextblick/gatsby-theme-hdb/src/gatsby-plugin-theme-ui";
import { merge } from "theme-ui";

// merge will deeply merge custom values with the theme's defaults
export default merge(baseTheme, {
  colors: {
    text: "#000",
    primary: "#008743",
    secondary: "#008743",
    backgroundLight: "#c6bebb",
    backgroundDark: "#575757",
    backgroundDarkText: "#fff",
    headerBg: "#fff",
  },
  fonts: {
    body: "Dax, sans-serif",
    heading: "Dax, sans-serif",
    headingSecondary: "Dax, sans-serif",
  },
});
